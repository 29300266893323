import { permissoesEnum, entidadeTipoEnum } from "@/types/enums";
import { subject } from "@casl/ability";

export default defineNuxtRouteMiddleware((to) => {
  const { $ability } = useNuxtApp();

  const routeName = String(to.name);
  const id = Number(to.params.id);

  // página de listagem
  if (
    routeName === "sh" &&
    $ability.cannot(permissoesEnum.VER_ROTA, entidadeTipoEnum.SOCIO_HONORARIO)
  ) {
    return navigateTo({
      name: "index",
    });
  }

  // página de cadastro
  if (
    routeName === "sh-cadastrar" &&
    $ability.cannot(
      permissoesEnum.CADASTRAR_SOCIO_HONORARIO,
      entidadeTipoEnum.SOCIO_HONORARIO
    )
  ) {
    return navigateTo({
      name: "sh",
    });
  }

  // página de visualização
  if (
    routeName === "sh-visualizar-id" &&
    $ability.cannot(
      permissoesEnum.VISUALIZAR_SOCIO_HONORARIO,
      subject(entidadeTipoEnum.SOCIO_HONORARIO, {
        id,
      })
    )
  ) {
    return navigateTo({
      name: "sh",
    });
  }

  // página de edição
  if (
    routeName === "sh-editar-id" &&
    $ability.cannot(
      permissoesEnum.EDITAR_SOCIO_HONORARIO,
      subject(entidadeTipoEnum.SOCIO_HONORARIO, {
        id,
      })
    ) &&
    $ability.cannot(
      permissoesEnum.CADASTRAR_EDITAR_REMOVER_CONTATOS_SOCIO_HONORARIO,
      subject(entidadeTipoEnum.SOCIO_HONORARIO, {
        id,
      })
    )
  ) {
    return navigateTo({
      name: "sh-visualizar-id",
      params: {
        id,
      },
    });
  }

  // página de convidar contato
  if (
    routeName === "sh-editar-id-convidar-usuario" &&
    $ability.cannot(
      permissoesEnum.CADASTRAR_EDITAR_REMOVER_CONTATOS_SOCIO_HONORARIO,
      subject(entidadeTipoEnum.SOCIO_HONORARIO, {
        id,
      })
    )
  ) {
    return navigateTo({
      name: "sh-editar-id",
      params: {
        id,
      },
    });
  }
});
